import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { useEtherBalance, useEthers, useTokenBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";


const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 15,
  },
  title: {
    fontSize: 14,
  },
});

const HomePage = () => {
  const classes = useStyles();
  const { account } = useEthers();
  const QQQAddress = "0x2822f6D1B2f41F93f33d937bc7d84A8Dfa4f4C21";
  const userBalance = useEtherBalance(account);
  const qqqBalance = useTokenBalance(QQQAddress, account);

  return (
    <Container maxWidth="md">
      <h1>Home Page</h1>
      <div>
        {account && (
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Account:
              </Typography>
              <Typography variant="h5" component="h2">
                {account}
              </Typography>
            </CardContent>
          </Card>
        )}
        {userBalance && (
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Ether balance:
              </Typography>
              <Typography variant="h5" component="h2">
                {formatEther(userBalance)} ETH
              </Typography>
            </CardContent>
          </Card>
        )}
        {qqqBalance && (
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                QQQ balance:
              </Typography>
              <Typography variant="h5" component="h2">
                {formatEther(qqqBalance)} QQQ
              </Typography>
            </CardContent>
          </Card>
        )}
      </div>
    </Container>
  );
};

export default HomePage;
