import React, { useEffect, useState } from "react";
import { useEthers, shortenAddress, useLookupAddress } from "@usedapp/core";
import styled from "styled-components";
import Web3Modal from "web3modal";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

import WalletConnectProvider from "@walletconnect/web3-provider";

import CustomerDialog from "./CustomerDialog";

export const Web3ModalButton = () => {
  const { account, activate, deactivate, chainId } = useEthers();
  const ens = useLookupAddress();
  const [activateError, setActivateError] = useState("");
  const { error } = useEthers();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error) {
      // Temp workaround to avoid network changed error message until useDapp handles this internally
      if (error?.message?.indexOf("underlying network changed") === -1) {
        setActivateError(error.message);
      }
    }
  }, [error]);

  useEffect(() => {
    // Can handle switches to unsupported chainId(s) here
    // console.log("Current chainId", chainId);
    if (chainId !== 1) {
      handleClickOpen()
    }
  }, [chainId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: "Metamask",
          description: "Connect with the provider in your Browser",
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          infuraId: process.env.REACT_APP_INFURA_ID,
        },
      },
    };

    const web3Modal = new Web3Modal({
      providerOptions,
    });

    try {
      const provider = await web3Modal.connect();
      await activate(provider);
    } catch (error: any) {
      setActivateError(error?.message);
    }
  };

  return (
    <Account>
      <ErrorWrapper>{activateError}</ErrorWrapper>
      {account ? (
        <>
          <AccountChip label={ens ?? shortenAddress(account)} />
          <Button color="inherit" onClick={() => deactivate()}>
            Disconnect
          </Button>
        </>
      ) : (
        <Button color="inherit" onClick={activateProvider}>
          Connect
        </Button>
      )}
      <CustomerDialog open={open} handleClose={handleClose} />
    </Account>
  );
};

const ErrorWrapper = styled.div`
  color: #ff3960;
  margin-right: 40px;
  margin-left: 40px;
  overflow: auto;
`;

const Account = styled.div`
  display: flex;
  align-items: center;
`;

const AccountChip = styled(Chip)`
  margin-right: 15px;
  color: white;
`;
